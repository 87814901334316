<template>
  <div class="container">
    <div class="row justify-content-center">
      <FullLoader />
    </div>
  </div>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import FullLoader from '@/components/MainContent/FullLoader'
import { trackEvent } from '@/lib/analytics'
import { createNamespacedHelpers } from 'vuex'
const IntegrationModule = createNamespacedHelpers('integration')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'OauthView',
  components: {
    MainContent,
    FullLoader,
  },
  computed: {
    integrationProviderName() {
      return this.$route && this.$route.params ? this.$route.params.integrationProviderName : null
    },
    queryParams() {
      return this.$route && this.$route.query ? this.$route.query : null
    },
  },
  methods: {
    ...IntegrationModule.mapActions(['updateIntegrationOauth']),
    ...CompanyModule.mapActions(['createSquareOauth']),
  },
  async mounted() {
    CompanyModule.mapGetters(['activeCompany'])

    trackEvent(this.$intercom, 'Oauth')
    let redirectUrl
    if (this.integrationProviderName == 'square') {
      const name = localStorage.getItem('oauthInputName')
      if (name) {
        const res = await this.createSquareOauth({
          integrationProviderName: this.integrationProviderName,
          name,
          oauth: this.queryParams,
        })

        if (res) {
          localStorage.removeItem('oauthInputName')
          redirectUrl = res.body.data.redirectUrl
        }
      } else {
        const result = await this.updateIntegrationOauth({
          integrationProviderName: this.integrationProviderName,
          oauth: this.queryParams,
        })
        redirectUrl = result.data.data.redirectUrl
      }
      this.$router.replace(redirectUrl)
    } else {
      const result = await this.updateIntegrationOauth({
        integrationProviderName: this.integrationProviderName,
        oauth: this.queryParams,
      })

      this.$router.replace(result.data.data.redirectUrl)
    }
  },
}
</script>

<style scoped lang="scss"></style>
